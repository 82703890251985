<template>
  <div
    :class="['parameters-root', {'is-prematch': isPrematch}]"
  >
    <Spinner
      v-if="(selectedParameterOption === 'global'
        || selectedParameterOption === 'team')
        && gameParamsLoading"
    />
    <Spinner
      v-if="selectedParameterOption === 'players' && playerParamsLoading"
    />
    <Spinner
      v-if="tradingEventLoading"
    />
    <div
      v-if="isTradingUIPageFrozen && !gameParamsLoading && !playerParamsLoading"
      class="pricing-loading-overlay"
    >
      <p>Simulating prices - please wait...</p>
    </div>
    <div
      class="parameters-root-content"
      :class="{ 'loading': gameParamsLoading || playerParamsLoading || tradingEventLoading }"
    >
      <div class="parameters-header">
        <div class="parameters-header-title">
          <h6>
            <span>Parameters</span>
            <Tooltip
              text="Go to param manager"
            >
              <Icon
                name="link-external"
                @click="goToParamSetup"
              />
            </Tooltip>
          </h6>
          <div class="game-mode-root">
            {{ isCalculatedMode ? 'Auto mode' : 'Manual mode' }}
            <Tooltip
              :text="isCalculatedMode ? 'Auto mode inputs parameters automatically ' : 'In manual mode user has to input parameter values manually'"
            >
              <Icon
                name="info"
              />
            </Tooltip>
          </div>
        </div>
        <div class="parameters-header-actions">
          <Button
            variant="secondary"
            icon="modem"
            :disabled="isSimulateBtnDisabled || isSimulateBtnFrozen"
            :loading="isSimulateBtnLoading"
            @click="onSimulate"
          >
            <span>Simulate</span>
            <span v-if="isSimulateBtnFrozen">
              ({{ countdownValue }})
            </span>
          </Button>
          <Button
            variant="primary"
            class="publish-btn"
            :class="{ 'disabled': isPublishBtnDisabled }"
            :icon="'upload'"
            :disabled="isPublishBtnDisabled"
            :loading="isPublishBtnLoading"
            @click="onPublish"
          >
            Publish
          </Button>
        </div>
      </div>
      <MultiviewPlayerParamsNewPricesAvailable
        v-if="simulateNewPricesMessageActive"
        @onSimulate="onSimulate"
      />
      <div
        class="tabs-select-root"
        v-if="hasTeamProps"
      >
        <div
          v-for="item in parameterSelectOption"
          :key="item.value"
          class="tabs-select-item parameters-select-item"
          :class="{'selected': item.value === selectedParameterOption}"
          @click="onParameterOptionSelect(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div
        v-if="showTeamsTab"
        class="tabs-select-root team-tabs-select-root"
      >
        <div
          v-for="item in teamSelectOptions"
          :key="item.value"
          class="tabs-select-item team-select-item"
          :class="{ 'selected': item.value === selectedTeamOption }"
          @click="onTeamOptionSelect(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div
        class="trading-parameters-container"
        :class="{ 'teams-tab-visible': showTeamsTab, 'simulate-new-prices-message-active': simulateNewPricesMessageActive}"
      >
        <GameParams
          v-if="selectedParameterOption === 'global'"
          class="trading-game-parameters"
          :global-params="globalParams"
          :is-tooltip-right="true"
          :is-calculated-mode="isCalculatedMode"
          :sport-id="sportId"
          @onParamChange="onGameParamChange"
          @onParamIncrease="onGameParamIncrease"
          @onParamDecrease="onGameParamDecrease"
        />
        <TeamParams
          v-if="selectedParameterOption === 'team'"
          class="trading-game-parameters"
          :selected-team="selectedTeamOption"
          :team-params="teamParams"
          :search-active="!!paramSearch"
          :is-tooltip-right="true"
          :is-calculated-mode="isCalculatedMode"
          :global-params="globalParams"
          :sport-id="sportId"
          @onParamChange="onGameParamChange"
          @onParamIncrease="onGameParamIncrease"
          @onParamDecrease="onGameParamDecrease"
        />
        <ParamsSetupMarkets
          v-if="selectedParameterOption === 'global' || selectedParameterOption === 'team'"
          :event-id="eventId"
        />
        <PlayerProps
          v-if="selectedParameterOption === 'players' && sportId !== BASEBALL_ID && sportId !== FOOTBALL_ID"
          :data="playerPropsData"
          :event="event"
          @onPropChange="onPlayerPropsUpdate"
          @onPropIncrease="onPlayerPropIncrease"
          @onPropDecrease="onPlayerPropDecrease"
        />
        <BaseballPlayerParamsByTeam
          v-if="selectedParameterOption === 'players' && sportId === BASEBALL_ID"
          :team-data="playerPropsData"
          :team-label="selectedTeamOption"
          :is-calculated-mode="isCalculatedMode"
          :current-catcher-index-prop="currentCatcherIndex"
        />
        <AmericanFootballPlayerParamsByTeam
          v-if="selectedParameterOption === 'players' && sportId === FOOTBALL_ID"
          :team-data="playerPropsData"
          :team-label="selectedTeamOption"
          :is-calculated-mode="isCalculatedMode"
          :current-catcher-index-prop="currentCatcherIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  map, cloneDeep, find, includes,
  filter, capitalize, orderBy, get,
  set,
} from 'lodash';
import PlayerProps from './PlayerProps';
import GameParams from '@/components/trading-ui/multiview/event-drawer/params/GameParams';
import TeamParams from '@/components/trading-ui/multiview/event-drawer/params/TeamParams';
import ParamsSetupMarkets from '@/components/player-setup/ParamsSetupMarkets';
import BaseballPlayerParamsByTeam from '@/components/player-setup/baseball/BaseballPlayerParamsByTeam';
import AmericanFootballPlayerParamsByTeam from '@/components/player-setup/setup-by-team/american-football/AmericanFootballPlayerParamsByTeam';
import MultiviewPlayerParamsNewPricesAvailable from '@/components/trading-ui/multiview/event-drawer/params/MultiviewPlayerParamsNewPricesAvailable';

import Button from '@/components/common/Button';
import Spinner from '@/components/common/Spinner';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import sportIds from '@/services/helpers/sports';
import { getPlayerPropIncreaseDecreaseValue, getLowerLimit, getUpperLimit } from '@/services/helpers/player-setup-mapper';
import {
  getGlobalParamsKeysBySport, getTeamParamsKeysByTeamAndSport,
  isBaseballGameParamsError, isBaseballGameParamsChanged,
  getBasketballGlobalParamsModelType, createOPCEParam,
} from '@/components/player-setup/params-helper';
import { parseEventModel } from '@/services/helpers/event-drawer';
import eventHelpers from '@/services/helpers/event';

const {
  FOOTBALL_ID,
  BASKETBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

const sportsWithTeamProps = [FOOTBALL_ID, BASKETBALL_ID, HOCKEY_ID, BASEBALL_ID];

export default {
  components: {
    PlayerProps,
    Button,
    GameParams,
    TeamParams,
    Spinner,
    ParamsSetupMarkets,
    BaseballPlayerParamsByTeam,
    Icon,
    Tooltip,
    MultiviewPlayerParamsNewPricesAvailable,
    AmericanFootballPlayerParamsByTeam,
  },
  props: {
    isPrematch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const tradingEvent = computed(() => store.getters.tradingEvent);
    const event = computed(() => parseEventModel(tradingEvent.value));
    const eventId = computed(() => tradingEvent.value?.eventId);
    const sportId = computed(() => tradingEvent.value?.sportId);
    const isSimulateBtnLoading = computed(() => store.getters.isSimulateBtnLoading);
    const isPublishBtnLoading = computed(() => store.getters.isPublishBtnLoading);
    const isSimulateBtnFrozen = computed(() => store.getters.isTradingUIPageSimulateBtnFrozen);
    const countdownValue = ref(10);
    const paramSearch = ref('');
    const simulateNewPricesMessageActive = computed(() => store.getters.tradingSimulateBySocketChangeEnabled);

    const playerProps = computed(() => store.getters.tradingPlayerProps);
    const hasTeamProps = computed(() => includes(sportsWithTeamProps, store.getters.tradingEvent?.sportId));
    const parameterSelectOption = ref([
      {
        value: 'global',
        label: 'Global',
      },
      {
        value: 'team',
        label: 'Team',
      },
      {
        value: 'players',
        label: 'Players',
      },
    ]);
    const selectedParameterOption = ref(parameterSelectOption.value[0]?.value);
    const isTradingUIPageFrozen = computed(() => (
      selectedParameterOption.value === 'players'
      && store.getters.isTradingUIPageFrozen)
    || (selectedParameterOption.value === 'global'
      && tradingEvent.value?.params?.isSimulatingPrices));
    const showTeamsTab = computed(() => selectedParameterOption.value === 'team' || selectedParameterOption.value === 'players');
    const teamSelectOptions = computed(() => {
      let options = [];
      if ((sportId.value !== BASEBALL_ID && sportId.value !== FOOTBALL_ID) || selectedParameterOption.value === 'team') {
        options.push({
          value: 'both',
          label: 'Both',
        });
      }
      options = [
        ...options || [],
        ...[
          {
            value: 'teamA',
            label: playerProps.value?.teamA?.name,
          },
          {
            value: 'teamB',
            label: playerProps.value?.teamB?.name,
          },
        ],
      ];
      return options;
    });

    const selectedTeamOption = ref(teamSelectOptions.value[0]?.value);
    const playerPropsData = computed(() => {
      let data;
      if (selectedTeamOption.value === 'both') {
        data = [
          ...map(playerProps.value?.teamA?.playerProps, ((player) => ({
            ...player,
            teamName: playerProps.value?.teamA.name,
            teamCode: 'teamA',
          }))) || [],
          ...map(playerProps.value?.teamB?.playerProps, ((player) => ({
            ...player,
            teamName: playerProps.value?.teamB.name,
            teamCode: 'teamB',
          }))) || [],
        ];
      } else {
        const eventDetails = eventHelpers.findEventDetails(tradingEvent.value);
        const teamLineup = (selectedTeamOption.value === 'teamA' && !tradingEvent.value?.isUsaView)
          || (selectedTeamOption.value === 'teamB' && tradingEvent.value?.isUsaView)
          ? eventDetails?.homeLineup : eventDetails?.awayLineup;
        data = map(playerProps.value?.[selectedTeamOption.value]?.playerProps,
          (player) => ({
            ...player,
            isInTeamLineup: includes(map(teamLineup, ({ playerId }) => playerId), player.playerId),
          }));
      }
      return orderBy(data, 'playerPositionIndex');
    });
    const currentCatcherIndex = computed(() => find(playerPropsData.value, ({ hitterParams }) => hitterParams?.isCatcher)?.playerIndex);
    const hasPlayerPropsData = computed(() => playerProps.value?.teamA?.playerProps?.length || playerProps.value?.teamB?.playerProps?.length);
    const onParameterOptionSelect = (option) => {
      selectedParameterOption.value = option;
      selectedTeamOption.value = teamSelectOptions.value[0]?.value;
    };
    const tradingEventLoading = computed(() => store.getters.tradingEventLoading);
    const gameParamsLoading = computed(() => store.getters.tradingGameParamsLoading);
    const playerParamsLoading = computed(() => store.getters.tradingPlayerPropsLoading);
    const gameParams = computed(() => tradingEvent.value?.params?.gameParams);
    const isCalculatedMode = computed(() => tradingEvent.value?.params?.selectedParamSource === 'CALCULATED');
    const globalParamsModelType = computed(() => getBasketballGlobalParamsModelType(event.value?.competitionType));
    const globalParamsKeys = computed(() => getGlobalParamsKeysBySport(tradingEvent.value?.sportId, globalParamsModelType.value));
    const globalParams = computed(() => (sportId.value === BASEBALL_ID ? gameParams.value
      : filter(
        filter(
          gameParams.value, (param) => includes(globalParamsKeys.value, param.key)
          && (includes(param.availableInMatchStates, tradingEvent.value.matchState) || !param.availableInMatchStates),
        ), (param) => includes(param.longName.toLowerCase(), paramSearch.value.toLowerCase()),
      )));
    const probabilities = computed(() => event.value?.probabilities);
    const teamParams = computed(() => {
      const isUSAView = tradingEvent.value?.isUsaView;
      const competitors = tradingEvent.value?.competitors;
      const home = find(competitors, { side: 'HOME' });
      const away = find(competitors, { side: 'AWAY' });

      const teamA = isUSAView ? away : home;
      const teamB = isUSAView ? home : away;

      const teamAParamsKeys = getTeamParamsKeysByTeamAndSport(capitalize(teamA?.side), tradingEvent.value?.sportId, globalParamsModelType.value);
      const teamBParamsKeys = getTeamParamsKeysByTeamAndSport(capitalize(teamB?.side), tradingEvent.value?.sportId, globalParamsModelType.value);

      const teamAParams = filter(
        filter(gameParams.value, (param) => includes(teamAParamsKeys, param.key)),
        (param) => includes(param.longName.toLowerCase(), paramSearch.value.toLowerCase()),
      );
      const teamBParams = filter(
        filter(gameParams.value, (param) => includes(teamBParamsKeys, param.key)),
        (param) => includes(param.longName.toLowerCase(), paramSearch.value.toLowerCase()),
      );

      // Basketball readonly param
      // Parameter that is retrieved from pricingProbabilities on the event level
      const OPCEHome = createOPCEParam(probabilities.value?.homePace);
      const OPCEAway = createOPCEParam(probabilities.value?.awayPace);
      return {
        teamA: {
          name: teamA?.name,
          params: sportId.value === BASKETBALL_ID ? [...teamAParams, OPCEAway] : teamAParams,
        },
        teamB: {
          name: teamB?.name,
          params: sportId.value === BASKETBALL_ID ? [...teamBParams, OPCEHome] : teamBParams,
        },
      };
    });
    const playerPropsSimulateBtnEnabled = computed(() => store.getters.isSimulateBtnEnabled);
    const playerPropsPublishBtnEnabled = computed(() => store.getters.isPublishBtnEnabled);
    const gameParamsDataValid = computed(() => (sportId.value === BASEBALL_ID
      ? !isBaseballGameParamsError(gameParams.value)
      : !find(gameParams.value, (param) => param?.isError)));

    const isSimulateBtnDisabled = computed(() => (!playerPropsSimulateBtnEnabled.value
      && !tradingEvent.value?.params?.isSimulateEnabled && !simulateNewPricesMessageActive.value)
      || !gameParamsDataValid.value);

    const isPublishBtnDisabled = computed(() => (!playerPropsPublishBtnEnabled.value
      && (sportId.value === BASEBALL_ID
        ? !isBaseballGameParamsChanged(gameParams.value)
        : !find(gameParams.value, (param) => param.value !== param.originalValue)
      ))
      || !gameParamsDataValid.value);

    const onTeamOptionSelect = (option) => {
      selectedTeamOption.value = option;
    };

    const onPlayerPropsUpdate = ({
      playerId, teamCode, param, value,
    }) => {
      const updatedPlayerProps = cloneDeep(playerProps.value);
      const updatedPlayer = find(updatedPlayerProps[teamCode || selectedTeamOption.value].playerProps, { playerId });
      set(updatedPlayer, param, value ? parseFloat(value) : null);
      store.dispatch('updateTradingPlayerProps', updatedPlayerProps);
    };

    const onPlayerPropIncrease = ({
      playerId, teamCode, param,
    }) => {
      const updatedPlayerProps = cloneDeep(playerProps.value);
      const updatedPlayer = find(updatedPlayerProps[teamCode || selectedTeamOption.value].playerProps, { playerId });
      if (get(updatedPlayer, param) >= getUpperLimit(param)) return;
      set(updatedPlayer, param, parseFloat((get(updatedPlayer, param) + getPlayerPropIncreaseDecreaseValue(param)).toFixed(3)));
      store.dispatch('updateTradingPlayerProps', updatedPlayerProps);
    };

    const onPlayerPropDecrease = ({
      playerId, teamCode, param,
    }) => {
      const updatedPlayerProps = cloneDeep(playerProps.value);
      const updatedPlayer = find(updatedPlayerProps[teamCode || selectedTeamOption.value].playerProps, { playerId });
      if (get(updatedPlayer, param) <= getLowerLimit(param)) return;
      set(updatedPlayer, param, parseFloat((get(updatedPlayer, param) - getPlayerPropIncreaseDecreaseValue(param)).toFixed(3)));
      store.dispatch('updateTradingPlayerProps', updatedPlayerProps);
    };

    const updateGameParams = (updatedParam) => {
      const action = sportId.value === BASEBALL_ID ? 'updateTradingUIBaseballGameParams' : 'updateTradingUIGameParams';
      store.dispatch(action, {
        updatedParam,
      });
    };

    const onGameParamChange = (value, param) => {
      const updatedParam = cloneDeep(param);
      if (param.type === 'boolean' || param.type === 'select' || param.type === 'text-toggle') {
        if (param.type === 'text-toggle' && param.value === value) {
          updatedParam.value = 'NEUTRAL';
        } else {
          updatedParam.value = value;
        }
        updateGameParams(updatedParam);
        return;
      }
      if (value) {
        const paramValue = parseFloat(value);
        const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
        updatedParam.value = paramValue;
        updatedParam.isError = isError;
      } else {
        updatedParam.isError = true;
        updatedParam.value = null;
      }
      updateGameParams(updatedParam);
    };

    const onGameParamIncrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue <= updatedParam.maxValue) {
        updatedParam.value = updatedValue;
        updateGameParams(updatedParam);
      }
    };

    const onGameParamDecrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue >= updatedParam.minValue) {
        updatedParam.value = updatedValue;
        updateGameParams(updatedParam);
      }
    };

    const onSimulate = () => {
      if (playerPropsSimulateBtnEnabled.value || simulateNewPricesMessageActive.value) {
        store.dispatch('simulateMarketPrices');
      }
      if ((tradingEvent.value?.params?.isSimulateEnabled && gameParamsDataValid.value) || simulateNewPricesMessageActive.value) {
        store.dispatch('simulateTradingUIGameParams');
      }
    };
    const onPublish = () => store.dispatch('submitTradingParameters');

    watch(() => isSimulateBtnFrozen.value, (isFrozen) => {
      if (isFrozen) {
        const interval = setInterval(() => {
          countdownValue.value -= 1;
          if (countdownValue.value === 0) {
            store.dispatch('setIsTradingUISimulateBtnFrozen', false);
            clearInterval(interval);
            countdownValue.value = 10;
          }
        }, 1000);
      }
    });

    const router = useRouter();
    const route = useRoute();
    const goToParamSetup = () => {
      router.push({
        name: 'params-setup',
        params: {
          eventId: route.params.eventId,
        },
      });
    };

    return {
      isSimulateBtnDisabled,
      isSimulateBtnLoading,
      isPublishBtnDisabled,
      isPublishBtnLoading,
      isTradingUIPageFrozen,
      isSimulateBtnFrozen,
      countdownValue,
      parameterSelectOption,
      selectedParameterOption,
      teamSelectOptions,
      selectedTeamOption,
      playerPropsData,
      hasTeamProps,
      hasPlayerPropsData,
      globalParams,
      teamParams,
      paramSearch,
      onParameterOptionSelect,
      onTeamOptionSelect,
      onPlayerPropsUpdate,
      onPlayerPropIncrease,
      onPlayerPropDecrease,
      onGameParamChange,
      onGameParamIncrease,
      onGameParamDecrease,
      onSimulate,
      onPublish,
      goToParamSetup,
      showTeamsTab,
      gameParamsLoading,
      eventId,
      event,
      playerParamsLoading,
      tradingEventLoading,
      isCalculatedMode,
      sportId,
      BASEBALL_ID,
      currentCatcherIndex,
      simulateNewPricesMessageActive,
      FOOTBALL_ID,
    };
  },
};
</script>

<style lang="scss">
.parameters-root {
  height: calc(100vh - 374px);
  background-color: $white;
  border: 1px solid $gray500;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &.is-prematch {
    height: 100%;
  }

  .parameters-root-content {
    height: 100%;
    &.loading {
      opacity: 0;
    }
  }
  .pricing-loading-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $gray300;
    z-index: $modalZIndex;
    p {
      padding: 16px;
      background-color: $white;
      width: 300px;
      border-radius: 4px;
    }
  }
  .parameters-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px;
    .parameters-header-title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      h6 {
        display: flex;
        align-items: center;
        gap: 8px;
        .tooltip-element {
          display: flex;
          .icon {
            cursor: pointer;
          }
        }
      }
      .game-mode-root {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $primary500;
        .icon {
          svg path {
            stroke: $gray600;
          }
        }
      }
    }
    .parameters-header-actions {
      display: flex;
      align-items: center;
      gap: 12px;

      .publish-btn {
        .icon svg path {
          fill: $white;
        }
        &.disabled {
          .icon svg path {
            fill: $gray700;
          }
        }
      }
    }
  }
  .tabs-select-root {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $gray600;
    &.team-tabs-select-root {
      margin-top: 8px;
    }
    .tabs-select-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 8px;
      position: relative;
      cursor: pointer;

      &.selected {
        &::before {
          content: '';
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          bottom: -2px;
          background-color: $brandPrimary500;
        }
      }
    }
  }
  .trading-parameters-container {
    height: calc(100% - 73px - 43px - 8px);
    overflow-y: auto;
    padding-top: 8px;
    &.teams-tab-visible {
      height: calc(100% - 73px - 43px - 43px - 8px);
    }
    &.simulate-new-prices-message-active {
      height: calc(100% - 73px - 43px - 43px - 48px);
    }
    .trading-game-parameters {
      padding: 0 16px 16px;
    }
    .baseball-player-params-by-team-root {
      .batters-pitchers-tabs {
        padding-top: 0;
        height: auto;
        .params-tabs-item {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          border-bottom: 2px solid transparent;
          padding: 10px 8px;

          &.active {
            border-bottom: 2px solid #003C3C;
          }
        }
      }
      .baseball-player-params-by-team-list-root {
        height: calc(100% - 38px);
      }
    }
  }
}
</style>
